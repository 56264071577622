import { GetPolicyResponsePaymentFlow } from "raci-caravanendorsement-clientproxy";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PremiumChangeState } from "../../components/FlowStateProvider/types";
import useFlowState from "../../hooks/useFlowState";
import usePolicyNumber from "../../hooks/usePolicyNumber";
import getB2CPCMUrl from "../../utils/getB2CPCMUrl";
import {
  MIDTERM_CONFIRMATION_PAGE_URL,
  MIDTERM_DIRECT_DEBIT_PAGE_URL,
  MIDTERM_PAYMENT_PAGE_URL,
  MIDTERM_REFUND_PAGE_URL,
  MIDTERM_REVIEW_PAGE_URL,
  MidtermPageHeadings,
  MidtermPageNames,
  MidtermRoutes,
  RenewalRoutes,
  midtermRoutes,
  renewalRoutes,
} from "../routes.config";
import { RouteInformation, StepInformation } from "../types";
import { UseRoutesResults } from "./types";

const filterRoutes = (routes: RouteInformation[], ...removeKeys: string[]): RouteInformation[] => {
  return routes.filter((item) => !removeKeys.includes(item.key));
};

const isAnnualInstalment = (paymentFlow?: GetPolicyResponsePaymentFlow) =>
  paymentFlow === GetPolicyResponsePaymentFlow.AnnualInstalmentsPaidInFull ||
  paymentFlow === GetPolicyResponsePaymentFlow.AnnualInstalmentsUnpaid;

/** Should navigate to the unique page for instalments after "Your Premium" if the midterm policy:
 *  - is annual instalments with no premium refund, or
 *  - is monthly instalments that's not fully paid, or
 *  - is monthly instalments that's fully paid with no premium refund
 */
export const shouldGoToDirectDebitPage = (
  paymentFlow: GetPolicyResponsePaymentFlow | undefined,
  premiumChangeState: PremiumChangeState | undefined,
) => {
  const isMonthlyUnpaid = paymentFlow === GetPolicyResponsePaymentFlow.MonthlyInstalmentsUnpaid;
  const isMonthlyFullyPaid = paymentFlow === GetPolicyResponsePaymentFlow.MonthlyInstalmentsPaidInFull;
  const isNotARefund = premiumChangeState !== PremiumChangeState.DECREASE;

  return isMonthlyUnpaid || (isNotARefund && (isAnnualInstalment(paymentFlow) || isMonthlyFullyPaid));
};

export const useRoutes = (): UseRoutesResults => {
  const location = useLocation();
  const navigate = useNavigate();
  const policyNumber = usePolicyNumber();
  const [flowState] = useFlowState();

  let formRoutes = flowState.isMidterm ? midtermRoutes : renewalRoutes;
  const paymentFlow = flowState.paymentFlow;
  const isIncreasingPremium = flowState.premiumChange === PremiumChangeState.INCREASE;
  const isDecreasingPremium = flowState.premiumChange === PremiumChangeState.DECREASE;

  if (flowState.isVehicleConfirmed !== false) {
    const updateYourCaravanRoute = flowState.isMidterm
      ? MidtermRoutes.UpdateYourCaravan
      : RenewalRoutes.UpdateYourCaravan;
    formRoutes = filterRoutes(formRoutes, updateYourCaravanRoute);
  }

  if (flowState.isMidterm) {
    // Filter the midterm Review routes based on the premium state and policy payment type
    if (shouldGoToDirectDebitPage(paymentFlow, flowState.premiumChange)) {
      formRoutes = filterRoutes(formRoutes, MidtermRoutes.Payment, MidtermRoutes.Review, MidtermRoutes.Refund);
    } else if (isIncreasingPremium) {
      formRoutes = filterRoutes(formRoutes, MidtermRoutes.Review, MidtermRoutes.Refund, MidtermRoutes.DirectDebit);
    } else if (isDecreasingPremium) {
      formRoutes = filterRoutes(formRoutes, MidtermRoutes.Payment, MidtermRoutes.Review, MidtermRoutes.DirectDebit);
    } else {
      formRoutes = filterRoutes(formRoutes, MidtermRoutes.Payment, MidtermRoutes.Refund, MidtermRoutes.DirectDebit);
    }

    const visibleReviewRoutes = [
      MIDTERM_PAYMENT_PAGE_URL,
      MIDTERM_REFUND_PAGE_URL,
      MIDTERM_REVIEW_PAGE_URL,
      MIDTERM_DIRECT_DEBIT_PAGE_URL,
      MIDTERM_CONFIRMATION_PAGE_URL,
    ];

    // ...but never show the Review routes unless we're sufficiently far enough in the flow
    if (!visibleReviewRoutes.includes(location.pathname)) {
      formRoutes = filterRoutes(
        formRoutes,
        MidtermRoutes.Payment,
        MidtermRoutes.Refund,
        MidtermRoutes.Review,
        MidtermRoutes.DirectDebit,
      );
    }

    // If we're showing the member the Direct Debit page we should rename the page to match the Payment page if
    // they're increasing their premium and will need to pay i.e. are an annual instalment or fully paid
    // monthly instalment policy
    const directDebitRoute = formRoutes.find((route) => route.key === MidtermRoutes.DirectDebit);
    if (directDebitRoute) {
      const shouldRenameDirectDebit =
        isIncreasingPremium &&
        (paymentFlow === GetPolicyResponsePaymentFlow.MonthlyInstalmentsPaidInFull || isAnnualInstalment(paymentFlow));

      if (shouldRenameDirectDebit) {
        directDebitRoute.name = MidtermPageNames.Payment;
        directDebitRoute.heading = MidtermPageHeadings.Payment;
      } else {
        directDebitRoute.name = MidtermPageNames.Review;
        directDebitRoute.heading = MidtermPageHeadings.Review;
      }
    }
  }

  const steps: StepInformation[] = formRoutes;
  const isEndorsementFlow = formRoutes.filter((item) => item.path === location.pathname).length > 0;

  const activeStepIndex = isEndorsementFlow ? steps.findIndex((item) => item.path === location.pathname) : undefined;
  const totalStepCount = isEndorsementFlow ? steps.length : undefined;
  const previousPageUrl = activeStepIndex && activeStepIndex > 0 ? formRoutes[activeStepIndex - 1].path : undefined;

  const navigateToPreviousStep = useMemo(() => {
    if (!isEndorsementFlow) {
      return undefined;
    }

    return previousPageUrl && activeStepIndex !== undefined && activeStepIndex > 0
      ? () => navigate(-1)
      : () => {
          window.location.href = getB2CPCMUrl(policyNumber ?? "");
        };
  }, [navigate, policyNumber, isEndorsementFlow, activeStepIndex, previousPageUrl]);

  const canNavigateToPreviousStep =
    !!navigateToPreviousStep &&
    !flowState.policyPaymentState?.isPaymentMethodLocked &&
    steps.indexOf(formRoutes[formRoutes.length - 1]) !== activeStepIndex;

  const canNavigateToDestinationIndex = (destinationIndex: number) =>
    canNavigateToPreviousStep === true &&
    activeStepIndex !== undefined &&
    destinationIndex !== -1 &&
    destinationIndex < activeStepIndex;

  const navigateToDestinationIndex = (destinationIndex: number) => {
    if (canNavigateToDestinationIndex(destinationIndex)) {
      navigate(destinationIndex - activeStepIndex!);
    }
  };

  const navigateToDestinationStep = (destinationRouteKey: string) => {
    const destinationIndex = formRoutes.findIndex(({ key }) => key === destinationRouteKey);
    navigateToDestinationIndex(destinationIndex);
  };

  return {
    formRoutes,
    steps,
    activeStepIndex,
    totalStepCount,
    canNavigateToPreviousStep,
    canNavigateToDestinationIndex,
    navigateToPreviousStep,
    navigateToDestinationIndex,
    navigateToDestinationStep,
  };
};

export default useRoutes;
