import { FLOW_TITLE_MIDTERM, FLOW_TITLE_RENEW } from "../../constants";

/**
 * Get the title for the page.
 * The midterm flow is the default as its wording across the app is more generic
 */
export const getFlowTitle = (isMidterm?: boolean): string => {
  switch (isMidterm) {
    case true:
      return FLOW_TITLE_MIDTERM;
    case false:
      return FLOW_TITLE_RENEW;
    default:
      return FLOW_TITLE_MIDTERM;
  }
};

export default getFlowTitle;
