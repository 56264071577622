import { EndorsePolicyResponse, GetPolicyResponsePaymentFlow } from "raci-caravanendorsement-clientproxy";

/** The possible outcomes on the premium for a midterm rating */
export enum PremiumChangeState {
  INCREASE = "Increase",
  DECREASE = "Decrease",
  NO_CHANGE = "No change",
}

/**
 * Payment state to manage Westpac realtime payment and card registration errors and retry behaviour.
 */
export interface PolicyPaymentState {
  /**
   * Indicates that Westpac Realtime payment retry triggered
   * which means that endorsement has been committed to Shield,
   * so navigation and form fields are locked down.
   */
  isPaymentMethodLocked: boolean;
  /** Hide and show Westpac Retry dialog for Realtime Payments and Card Registrations */
  showWestpacRetryDialog: boolean;
  /** Has a realtime payment error which is used to determine which confirmation page to display */
  hasPaymentError: boolean;
}

export interface FlowState {
  /** Is the members session for mid-term endorsement */
  isMidterm: boolean;
  /** Is the policy either renew or midterm */
  isPolicyTypeDetermined: boolean;
  /** Error code when has error */
  errorCode?: number;
  /**
   * Has the members session erred.
   *
   * SPK-7005 - Enforce error state for session
   * Member will be blocked from navigating
   * back from an error page and keep trying.
   * RouteGuard will redirect the member to
   * SystemUnavailable page if hasError is true and
   * current location is not one of the error pages.
   */
  hasError: boolean;
  /** Has the members session timed out */
  hasTimedOut: boolean;
  /** Has the member completed the flow */
  isCompleted: boolean;
  /** Whether the vehicle has been confirmed as the current vehicle for the policy */
  isVehicleConfirmed?: boolean;
  /** State to determine if payment has erred or is locked or is retry dialog should be shown */
  policyPaymentState: PolicyPaymentState;
  /** Which payment flow state the existing policy is in */
  paymentFlow?: GetPolicyResponsePaymentFlow;
  /** The current effect on the premium for a midterm policy*/
  premiumChange?: PremiumChangeState;
  /** Has the policy endorsement been completed successfully */
  endorsePolicyResponse?: EndorsePolicyResponse;
}

export const DEFAULT_POLICY_PAYMENT_STATE: PolicyPaymentState = {
  isPaymentMethodLocked: false,
  showWestpacRetryDialog: false,
  hasPaymentError: false,
};

export const DEFAULT_FLOW_STATE: FlowState = {
  /** Default isMidterm to true as its wording across the app is more generic */
  isMidterm: true,
  hasError: false,
  hasTimedOut: false,
  isCompleted: false,
  isPolicyTypeDetermined: false,
  policyPaymentState: DEFAULT_POLICY_PAYMENT_STATE,
};
