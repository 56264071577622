import { B2C_PCM_BASE_URL } from "../../constants";

export let getB2CPCMBaseUrl: () => string;

/*
 ** WARNING: KNOW WHAT YOU'RE DOING BEFORE MAKING CHANGES TO
 ** TO THE BELOW IF STATEMENT.
 ** UPON A BUILD, REACT SCRIPT WILL REMOVE CODE IN
 ** THE OUTPUTTED JAVASCRIPT FILE DEPENDING ON THE TARGET
 ** ENVIRONMENT
 ** THIS MEANS THAT FOR LOCAL/DEV/SIT BUILDS WILL INCLUDE
 ** AND SHIP CODE (YOU CAN SEE AND SEARCH FOR IN OUTPUTTED
 ** JAVASCRIPT ARTIFACTS) FOR MICROSOFT AUTHENTICATION LIBRARY
 */
if (
  process.env.REACT_APP_ENVIRONMENT === "LOCAL" ||
  process.env.REACT_APP_ENVIRONMENT === "DEV" ||
  process.env.REACT_APP_ENVIRONMENT === "SIT" ||
  process.env.REACT_APP_ENVIRONMENT === "UAT"
) {
  const { STORAGE_KEY_SOURCE_URL } = require("raci-react-library-npe");

  getB2CPCMBaseUrl = () => {
    const url = sessionStorage.getItem(STORAGE_KEY_SOURCE_URL) ?? B2C_PCM_BASE_URL;

    return decodeURIComponent(url ?? "");
  };
} else {
  getB2CPCMBaseUrl = () => B2C_PCM_BASE_URL;
}

export const getB2CPCMUrl = (policyNumber: string) => {
  const policyNumberQuery = "?policyNumber=";
  const baseUrl = getB2CPCMBaseUrl();
  return `${baseUrl}${baseUrl.includes(policyNumberQuery) ? "" : policyNumberQuery}${policyNumber}`;
};

export default getB2CPCMUrl;
