import { CaravanEndorsementApiException } from "raci-caravanendorsement-clientproxy";
import { HTTP_STATUS_CODE_BAD_REQUEST, HTTP_STATUS_CODE_CONTACT_SYNC_FAILURE } from "raci-react-library";
import { useLocation, useNavigate } from "react-router-dom";
import { UTILITY_CALL_US_PAGE_URL, UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL } from "../../routing/routes.config";
import useFlowState from "../useFlowState";

export const useExceptionHandler = () => {
  const [flowState, setFlowState] = useFlowState();
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * Handle exception navigation based on exception status code.
   *
   * Default error Utility page is SystemUnavailable.
   *
   * FlowState.HasError will also be updated to true to allow enforcement
   * of session error state via RouteGuard which will redirect member back
   * to the default error Utility page if they try to navigate away.
   *
   * Setting replace to true will replace the entry in browser history,
   * so only set it to true in appropriate scenarios, such as in the usePreform
   * hook where user should not be able to navigate back to the loading/splash
   * screen and would be navigated back to PCM/myRAC.
   *
   * @param requestName request that triggered the exception
   * @param exception optional param to determine which error page to navigate to based on status code
   * @param replace optional param to replace page history on navigate (default false)
   */
  const handleExceptionNavigation = (requestName: string, exception?: any, replace?: boolean) => {
    const statusCode = (exception as CaravanEndorsementApiException)?.status;

    if (statusCode === HTTP_STATUS_CODE_CONTACT_SYNC_FAILURE || statusCode === HTTP_STATUS_CODE_BAD_REQUEST) {
      setFlowState({ ...flowState, errorCode: statusCode });
      navigateToUtilityPage(UTILITY_CALL_US_PAGE_URL, requestName, replace);
    } else {
      setFlowState({ ...flowState, errorCode: statusCode });
      navigateToUtilityPage(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL, requestName, replace);
    }
  };

  const navigateToUtilityPage = (url: string, requestName: string, replace: boolean = false) => {
    navigate(url, {
      replace,
      state: {
        referrer: `${requestName} ${location.pathname}`,
      },
    });
  };

  return handleExceptionNavigation;
};

export default useExceptionHandler;
