import { lazy } from "react";
import { RouteInformation } from "./types";

const CallUs = lazy(() => import("../../views/CallUs"));
const Roadblock = lazy(() => import("../../views/Roadblock"));
const SessionTimeout = lazy(() => import("../../views/SessionTimeout"));
const SystemUnavailable = lazy(() => import("../../views/SystemUnavailable"));

const PreForm = lazy(() => import("../../views/PreForm"));
const YourCaravan = lazy(() => import("../../views/YourCaravan"));
const UpdateYourCaravan = lazy(() => import("../../views/UpdateYourCaravan"));
const YourCaravanUsage = lazy(() => import("../../views/YourCaravanUsage"));
const YourPremium = lazy(() => import("../../views/YourPremium"));
const PaymentDetails = lazy(() => import("../../views/PaymentDetails"));
const Confirmation = lazy(() => import("../../views/Confirmation"));

const StartDate = lazy(() => import("../../views/StartDate"));
const MidtermReview = lazy(() => import("../../views/MidtermReview"));
const MidtermPayment = lazy(() => import("../../views/MidtermPayment"));
const MidtermRefund = lazy(() => import("../../views/MidtermRefund"));
const MidtermDirectDebit = lazy(() => import("../../views/MidtermDirectDebit"));

export enum PreFormRoute {
  PreForm = "",
}

export enum RenewalRoutes {
  YourCaravan = "your-caravan-or-trailer",
  UpdateYourCaravan = "update-make-and-model",
  YourCaravanUsage = "storage-and-use",
  YourPremium = "your-premium",
  Payment = "payment",
  Confirmation = "confirmation",
}

export enum MidtermRoutes {
  StartDate = "start-date",
  YourCaravan = "your-caravan-or-trailer",
  UpdateYourCaravan = "update-make-and-model",
  YourCaravanUsage = "storage-and-use",
  YourPremium = "your-premium",
  Review = "review",
  Payment = "payment",
  Refund = "refund",
  DirectDebit = "direct-debit",
  Confirmation = "confirmation",
}

export enum UtilityRoutes {
  CallUs = "call-us",
  Roadblock = "roadblock",
  SessionTimeout = "408",
  SystemUnavailable = "error",
}

export enum PreFormPageNames {
  PreForm = "",
}

export enum RenewalPageNames {
  YourCaravan = "Your caravan or trailer",
  UpdateYourCaravan = "Update make and model",
  YourCaravanUsage = "Storage and use",
  YourPremium = "Your premium",
  Payment = "Payment",
  Confirmation = "Confirmation",
}

export enum MidtermPageNames {
  StartDate = "Start date",
  YourCaravan = "Your caravan or trailer",
  UpdateYourCaravan = "Update make and model",
  YourCaravanUsage = "Storage and use",
  YourPremium = "Your premium",
  Payment = "Payment",
  Refund = "Refund",
  Review = "Review",
  Confirmation = "Confirmation",
}

export enum EndorsementPageHeadings {
  YourCaravan = RenewalPageNames.YourCaravan,
  UpdateYourCaravan = "Let's update your caravan or trailer",
  YourCaravanUsage = RenewalPageNames.YourCaravanUsage,
}

export enum RenewalPageHeadings {
  YourPremium = "Here's your renewal",
  Payment = "Pay your renewal",
}

export enum MidtermPageHeadings {
  StartDate = "Start date",
  YourPremium = "Here's your premium",
  Payment = "Pay your premium",
  Refund = "Get your refund",
  Review = "Review your policy",
}

export enum UtilityPageNames {
  CallUs = "Call us",
  Roadblock = "Uh oh!",
  SessionTimeout = "Session timed out",
  SystemUnavailable = "System unavailable",
}

export enum UtilityPageHeadings {
  Default = "Uh oh!",
  CallUs = Default,
  Roadblock = Default,
  SessionTimeout = Default,
  SystemUnavailable = Default,
}

export const getRenewalRouteUrl = (route: RenewalRoutes | string) => `${process.env.REACT_APP_HOMEPAGE}/renew/${route}`;
export const getMidtermRouteUrl = (route: MidtermRoutes | string) =>
  `${process.env.REACT_APP_HOMEPAGE}/update/${route}`;
export const getUtilityRouteUrl = (route: UtilityRoutes | string) => `${process.env.REACT_APP_HOMEPAGE}/${route}`;

export const PRE_FORM_PAGE_URL = `${process.env.REACT_APP_HOMEPAGE}`;

export const RENEWAL_YOUR_CARAVAN_PAGE_URL = getRenewalRouteUrl(RenewalRoutes.YourCaravan);
export const RENEWAL_UPDATE_YOUR_CARAVAN_PAGE_URL = getRenewalRouteUrl(RenewalRoutes.UpdateYourCaravan);
export const RENEWAL_YOUR_CARAVAN_USAGE_PAGE_URL = getRenewalRouteUrl(RenewalRoutes.YourCaravanUsage);
export const RENEWAL_YOUR_PREMIUM_PAGE_URL = getRenewalRouteUrl(RenewalRoutes.YourPremium);
export const RENEWAL_PAYMENT_PAGE_URL = getRenewalRouteUrl(RenewalRoutes.Payment);
export const RENEWAL_CONFIRMATION_PAGE_URL = getRenewalRouteUrl(RenewalRoutes.Confirmation);

export const MIDTERM_START_DATE_PAGE_URL = getMidtermRouteUrl(MidtermRoutes.StartDate);
export const MIDTERM_YOUR_CARAVAN_PAGE_URL = getMidtermRouteUrl(MidtermRoutes.YourCaravan);
export const MIDTERM_UPDATE_YOUR_CARAVAN_PAGE_URL = getMidtermRouteUrl(MidtermRoutes.UpdateYourCaravan);
export const MIDTERM_YOUR_CARAVAN_USAGE_PAGE_URL = getMidtermRouteUrl(MidtermRoutes.YourCaravanUsage);
export const MIDTERM_YOUR_PREMIUM_PAGE_URL = getMidtermRouteUrl(MidtermRoutes.YourPremium);
export const MIDTERM_PAYMENT_PAGE_URL = getMidtermRouteUrl(MidtermRoutes.Payment);
export const MIDTERM_REFUND_PAGE_URL = getMidtermRouteUrl(MidtermRoutes.Refund);
export const MIDTERM_REVIEW_PAGE_URL = getMidtermRouteUrl(MidtermRoutes.Review);
export const MIDTERM_DIRECT_DEBIT_PAGE_URL = getMidtermRouteUrl(MidtermRoutes.DirectDebit);
export const MIDTERM_CONFIRMATION_PAGE_URL = getMidtermRouteUrl(MidtermRoutes.Confirmation);

export const UTILITY_CALL_US_PAGE_URL = getUtilityRouteUrl(UtilityRoutes.CallUs);
export const UTILITY_ROADBLOCK_PAGE_URL = getUtilityRouteUrl(UtilityRoutes.Roadblock);
export const UTILITY_SESSION_TIMEOUT_PAGE_URL = getUtilityRouteUrl(UtilityRoutes.SessionTimeout);
export const UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL = getUtilityRouteUrl(UtilityRoutes.SystemUnavailable);

const preFormRoutesInfo: Record<PreFormRoute, RouteInformation> = {
  [PreFormRoute.PreForm]: {
    key: "pre-form",
    path: PRE_FORM_PAGE_URL,
    name: PreFormPageNames.PreForm,
    element: <PreForm />,
  },
};

export const renewalRoutesInfo: Record<RenewalRoutes, RouteInformation> = {
  [RenewalRoutes.YourCaravan]: {
    key: RenewalRoutes.YourCaravan,
    path: RENEWAL_YOUR_CARAVAN_PAGE_URL,
    name: RenewalPageNames.YourCaravan,
    heading: EndorsementPageHeadings.YourCaravan,
    element: <YourCaravan />,
  },
  [RenewalRoutes.UpdateYourCaravan]: {
    key: RenewalRoutes.UpdateYourCaravan,
    path: RENEWAL_UPDATE_YOUR_CARAVAN_PAGE_URL,
    name: RenewalPageNames.UpdateYourCaravan,
    heading: EndorsementPageHeadings.UpdateYourCaravan,
    element: <UpdateYourCaravan />,
  },
  [RenewalRoutes.YourCaravanUsage]: {
    key: RenewalRoutes.YourCaravanUsage,
    path: RENEWAL_YOUR_CARAVAN_USAGE_PAGE_URL,
    name: RenewalPageNames.YourCaravanUsage,
    heading: EndorsementPageHeadings.YourCaravanUsage,
    element: <YourCaravanUsage />,
  },
  [RenewalRoutes.YourPremium]: {
    key: RenewalRoutes.YourPremium,
    path: RENEWAL_YOUR_PREMIUM_PAGE_URL,
    name: RenewalPageNames.YourPremium,
    heading: RenewalPageHeadings.YourPremium,
    element: <YourPremium />,
  },
  [RenewalRoutes.Payment]: {
    key: RenewalRoutes.Payment,
    path: RENEWAL_PAYMENT_PAGE_URL,
    name: RenewalPageNames.Payment,
    heading: RenewalPageHeadings.Payment,
    element: <PaymentDetails />,
  },
  [RenewalRoutes.Confirmation]: {
    key: RenewalRoutes.Confirmation,
    path: RENEWAL_CONFIRMATION_PAGE_URL,
    name: RenewalPageNames.Confirmation,
    element: <Confirmation />,
  },
};

export const midtermRoutesInfo: Record<MidtermRoutes, RouteInformation> = {
  [MidtermRoutes.StartDate]: {
    key: MidtermRoutes.StartDate,
    path: MIDTERM_START_DATE_PAGE_URL,
    name: MidtermPageNames.StartDate,
    heading: MidtermPageHeadings.StartDate,
    element: <StartDate />,
  },
  [MidtermRoutes.YourCaravan]: {
    key: MidtermRoutes.YourCaravan,
    path: MIDTERM_YOUR_CARAVAN_PAGE_URL,
    name: MidtermPageNames.YourCaravan,
    heading: EndorsementPageHeadings.YourCaravan,
    element: <YourCaravan />,
  },
  [MidtermRoutes.UpdateYourCaravan]: {
    key: MidtermRoutes.UpdateYourCaravan,
    path: MIDTERM_UPDATE_YOUR_CARAVAN_PAGE_URL,
    name: MidtermPageNames.UpdateYourCaravan,
    heading: EndorsementPageHeadings.UpdateYourCaravan,
    element: <UpdateYourCaravan />,
  },
  [MidtermRoutes.YourCaravanUsage]: {
    key: MidtermRoutes.YourCaravanUsage,
    path: MIDTERM_YOUR_CARAVAN_USAGE_PAGE_URL,
    name: MidtermPageNames.YourCaravanUsage,
    heading: EndorsementPageHeadings.YourCaravanUsage,
    element: <YourCaravanUsage />,
  },
  [MidtermRoutes.YourPremium]: {
    key: MidtermRoutes.YourPremium,
    path: MIDTERM_YOUR_PREMIUM_PAGE_URL,
    name: MidtermPageNames.YourPremium,
    heading: MidtermPageHeadings.YourPremium,
    element: <YourPremium />,
  },
  [MidtermRoutes.Payment]: {
    key: MidtermRoutes.Payment,
    path: MIDTERM_PAYMENT_PAGE_URL,
    name: MidtermPageNames.Payment,
    heading: MidtermPageHeadings.Payment,
    element: <MidtermPayment />,
  },
  [MidtermRoutes.Refund]: {
    key: MidtermRoutes.Refund,
    path: MIDTERM_REFUND_PAGE_URL,
    name: MidtermPageNames.Refund,
    heading: MidtermPageHeadings.Refund,
    element: <MidtermRefund />,
  },
  [MidtermRoutes.Review]: {
    key: MidtermRoutes.Review,
    path: MIDTERM_REVIEW_PAGE_URL,
    name: MidtermPageNames.Review,
    heading: MidtermPageHeadings.Review,
    element: <MidtermReview />,
  },
  [MidtermRoutes.DirectDebit]: {
    key: MidtermRoutes.DirectDebit,
    path: MIDTERM_DIRECT_DEBIT_PAGE_URL,
    name: MidtermPageNames.Review,
    heading: MidtermPageHeadings.Review,
    element: <MidtermDirectDebit />,
  },
  [MidtermRoutes.Confirmation]: {
    key: MidtermRoutes.Confirmation,
    path: MIDTERM_CONFIRMATION_PAGE_URL,
    name: MidtermPageNames.Confirmation,
    element: <Confirmation />,
  },
};

export const preFormRoutes: RouteInformation[] = [preFormRoutesInfo[PreFormRoute.PreForm]];

export const renewalRoutes: RouteInformation[] = [
  renewalRoutesInfo[RenewalRoutes.YourCaravan],
  renewalRoutesInfo[RenewalRoutes.UpdateYourCaravan],
  renewalRoutesInfo[RenewalRoutes.YourCaravanUsage],
  renewalRoutesInfo[RenewalRoutes.YourPremium],
  renewalRoutesInfo[RenewalRoutes.Payment],
  renewalRoutesInfo[RenewalRoutes.Confirmation],
];

export const midtermRoutes: RouteInformation[] = [
  midtermRoutesInfo[MidtermRoutes.StartDate],
  midtermRoutesInfo[MidtermRoutes.YourCaravan],
  midtermRoutesInfo[MidtermRoutes.UpdateYourCaravan],
  midtermRoutesInfo[MidtermRoutes.YourCaravanUsage],
  midtermRoutesInfo[MidtermRoutes.YourPremium],
  midtermRoutesInfo[MidtermRoutes.Payment],
  midtermRoutesInfo[MidtermRoutes.Refund],
  midtermRoutesInfo[MidtermRoutes.Review],
  midtermRoutesInfo[MidtermRoutes.DirectDebit],
  midtermRoutesInfo[MidtermRoutes.Confirmation],
];

export const utilityRoutes: RouteInformation[] = [
  {
    key: UtilityRoutes.CallUs,
    path: UTILITY_CALL_US_PAGE_URL,
    name: UtilityPageNames.CallUs,
    element: <CallUs />,
    heading: UtilityPageHeadings.CallUs,
  },
  {
    key: UtilityRoutes.Roadblock,
    path: UTILITY_ROADBLOCK_PAGE_URL,
    name: UtilityPageNames.Roadblock,
    element: <Roadblock />,
    heading: UtilityPageHeadings.Roadblock,
  },
  {
    key: UtilityRoutes.SessionTimeout,
    path: UTILITY_SESSION_TIMEOUT_PAGE_URL,
    name: UtilityPageNames.SessionTimeout,
    element: <SessionTimeout />,
    heading: UtilityPageHeadings.SessionTimeout,
  },
  {
    key: UtilityRoutes.SystemUnavailable,
    path: UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
    name: UtilityPageNames.SystemUnavailable,
    element: <SystemUnavailable />,
    heading: UtilityPageHeadings.SystemUnavailable,
  },
];

export const allRoutes = [...renewalRoutes, ...midtermRoutes, ...utilityRoutes];

/**
 * Disable RouteGuard checks for all utility pages and for all confirmation pages.
 * Once confirmation page has been reached, user should not be able to navigate back from confirmation.
 */
export const disableRouteGuardChecks = utilityRoutes
  .map((item) => item.path)
  .concat(PRE_FORM_PAGE_URL)
  .concat(RENEWAL_CONFIRMATION_PAGE_URL)
  .concat(MIDTERM_CONFIRMATION_PAGE_URL);
