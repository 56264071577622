import { CaravanEndorsementApiClient } from "raci-caravanendorsement-clientproxy";
import { LookupServiceApiClient } from "raci-lookup-service-clientproxy";
import { MfaOtpApiClient } from "raci-otp-service-clientproxy";
import { useSessionIdStorageKey } from "raci-react-library";
import { useSsoApiClient } from "raci-react-library-npe";
import {
  ADB2C_CLIENT_ID,
  BFF_API_BASE_URL,
  LOOKUP_SERVICE_API_BASE_URL,
  MFA_OTP_SERVICE_API_BASE_URL,
} from "../../shared/constants";
import { ENVIRONMENT, NPE_OAUTH_AUTHORITY, NPE_OAUTH_CLIENT_ID } from "../constants";

export const useNpeBffApiClient = () => {
  return useSsoApiClient({
    Client: CaravanEndorsementApiClient,
    clientId: ADB2C_CLIENT_ID,
    apiBaseUrl: BFF_API_BASE_URL,
    sessionIdStorageKey: useSessionIdStorageKey(),
    environment: ENVIRONMENT,
    npeClientId: NPE_OAUTH_CLIENT_ID,
    npeAuthority: NPE_OAUTH_AUTHORITY,
  });
};

export const useNpeLookupServiceApiClient = () => {
  return useSsoApiClient({
    Client: LookupServiceApiClient,
    clientId: ADB2C_CLIENT_ID,
    apiBaseUrl: LOOKUP_SERVICE_API_BASE_URL,
    sessionIdStorageKey: useSessionIdStorageKey(),
    environment: ENVIRONMENT,
    npeClientId: NPE_OAUTH_CLIENT_ID,
    npeAuthority: NPE_OAUTH_AUTHORITY,
  });
};

export const useNpeMfaOtpServiceApiClient = () => {
  return useSsoApiClient({
    Client: MfaOtpApiClient,
    clientId: ADB2C_CLIENT_ID,
    apiBaseUrl: MFA_OTP_SERVICE_API_BASE_URL,
    sessionIdStorageKey: useSessionIdStorageKey(),
    environment: ENVIRONMENT,
    npeClientId: NPE_OAUTH_CLIENT_ID,
    npeAuthority: NPE_OAUTH_AUTHORITY,
  });
};
