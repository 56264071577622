import { CaravanEndorsementApiClient } from "raci-caravanendorsement-clientproxy";
import { LookupServiceApiClient } from "raci-lookup-service-clientproxy";
import { MfaOtpApiClient } from "raci-otp-service-clientproxy";
import { useSessionIdStorageKey, useSsoApiClient } from "raci-react-library";
import {
  ADB2C_CLIENT_ID,
  BFF_API_BASE_URL,
  LOOKUP_SERVICE_API_BASE_URL,
  MFA_OTP_SERVICE_API_BASE_URL,
} from "../../constants";

const useRealBffApiClient = () => {
  return useSsoApiClient({
    Client: CaravanEndorsementApiClient,
    clientId: ADB2C_CLIENT_ID,
    apiBaseUrl: BFF_API_BASE_URL,
    sessionIdStorageKey: useSessionIdStorageKey(),
  });
};

const useRealLookupServiceApiClient = () => {
  return useSsoApiClient({
    Client: LookupServiceApiClient,
    clientId: ADB2C_CLIENT_ID,
    apiBaseUrl: LOOKUP_SERVICE_API_BASE_URL,
    sessionIdStorageKey: useSessionIdStorageKey(),
  });
};

const useRealMfaOtpServiceApiClient = () => {
  return useSsoApiClient({
    Client: MfaOtpApiClient,
    clientId: ADB2C_CLIENT_ID,
    apiBaseUrl: MFA_OTP_SERVICE_API_BASE_URL,
    sessionIdStorageKey: useSessionIdStorageKey(),
  });
};

export let useApiClient = useRealBffApiClient;
export let useLookupServiceApiClient = useRealLookupServiceApiClient;
export let useMfaOtpServiceApiClient = useRealMfaOtpServiceApiClient;

/*
 ** WARNING: KNOW WHAT YOU'RE DOING BEFORE MAKING CHANGES TO
 ** TO THE BELOW IF STATEMENT.
 ** UPON A BUILD, REACT SCRIPT WILL REMOVE CODE IN
 ** THE OUTPUTTED JAVASCRIPT FILE DEPENDING ON THE TARGET
 ** ENVIRONMENT
 ** THIS MEANS THAT FOR LOCAL/DEV/SIT BUILDS WILL INCLUDE
 ** AND SHIP CODE (YOU CAN SEE AND SEARCH FOR IN OUTPUTTED
 ** JAVASCRIPT ARTIFACTS) FOR MICROSOFT AUTHENTICATION LIBRARY
 */
if (
  process.env.REACT_APP_ENVIRONMENT === "LOCAL" ||
  process.env.REACT_APP_ENVIRONMENT === "DEV" ||
  process.env.REACT_APP_ENVIRONMENT === "SIT" ||
  process.env.REACT_APP_ENVIRONMENT === "UAT"
) {
  const { BypassService } = require("raci-react-library-npe");
  const {
    useNpeBffApiClient,
    useNpeLookupServiceApiClient,
    useNpeMfaOtpServiceApiClient,
  } = require("../../../__NPE__/useApiClient");

  useApiClient = useNpeBffApiClient;
  useLookupServiceApiClient = useNpeLookupServiceApiClient;
  useMfaOtpServiceApiClient = useNpeMfaOtpServiceApiClient;

  if (process.env.REACT_APP_ENVIRONMENT === "UAT" && !BypassService.isUsingTestLaunchPage()) {
    useApiClient = useRealBffApiClient;
    useLookupServiceApiClient = useRealLookupServiceApiClient;
    useMfaOtpServiceApiClient = useRealMfaOtpServiceApiClient;
  }
}

export default useApiClient;
