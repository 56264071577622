import {
  MIDTERM_CONFIRMATION_PAGE_URL,
  MIDTERM_PAYMENT_PAGE_URL,
  MIDTERM_UPDATE_YOUR_CARAVAN_PAGE_URL,
  MIDTERM_YOUR_CARAVAN_PAGE_URL,
  MIDTERM_YOUR_CARAVAN_USAGE_PAGE_URL,
  MIDTERM_YOUR_PREMIUM_PAGE_URL,
  RENEWAL_CONFIRMATION_PAGE_URL,
  RENEWAL_PAYMENT_PAGE_URL,
  RENEWAL_UPDATE_YOUR_CARAVAN_PAGE_URL,
  RENEWAL_YOUR_CARAVAN_PAGE_URL,
  RENEWAL_YOUR_CARAVAN_USAGE_PAGE_URL,
  RENEWAL_YOUR_PREMIUM_PAGE_URL,
} from "../../routing/routes.config";

export interface Navigation {
  midterm: string;
  renewal: string;
}

export const YOUR_CARAVAN_PAGE_NAVIGATION: Navigation = {
  midterm: MIDTERM_YOUR_CARAVAN_PAGE_URL,
  renewal: RENEWAL_YOUR_CARAVAN_PAGE_URL,
};

export const UPDATE_YOUR_CARAVAN_PAGE_NAVIGATION: Navigation = {
  midterm: MIDTERM_UPDATE_YOUR_CARAVAN_PAGE_URL,
  renewal: RENEWAL_UPDATE_YOUR_CARAVAN_PAGE_URL,
};

export const YOUR_CARAVAN_USAGE_PAGE_NAVIGATION: Navigation = {
  midterm: MIDTERM_YOUR_CARAVAN_USAGE_PAGE_URL,
  renewal: RENEWAL_YOUR_CARAVAN_USAGE_PAGE_URL,
};

export const YOUR_PREMIUM_PAGE_NAVIGATION: Navigation = {
  midterm: MIDTERM_YOUR_PREMIUM_PAGE_URL,
  renewal: RENEWAL_YOUR_PREMIUM_PAGE_URL,
};

export const PAYMENT_PAGE_NAVIGATION: Navigation = {
  midterm: MIDTERM_PAYMENT_PAGE_URL,
  renewal: RENEWAL_PAYMENT_PAGE_URL,
};

export const CONFIRMATION_PAGE_NAVIGATION: Navigation = {
  midterm: MIDTERM_CONFIRMATION_PAGE_URL,
  renewal: RENEWAL_CONFIRMATION_PAGE_URL,
};
