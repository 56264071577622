import { TrackPageChangesProps } from "raci-react-library";
import {
  PRE_FORM_PAGE_URL,
  UTILITY_CALL_US_PAGE_URL,
  UTILITY_ROADBLOCK_PAGE_URL,
  UTILITY_SESSION_TIMEOUT_PAGE_URL,
  UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
} from "../routing/routes.config";

// <------------------>
//  Common
// <------------------>
export const SESSION_KEY_PREFIX = "RAC_CEO_";
export const TBA_VEHICLE_REGISTRATION = "TBA";

// <------------------>
//  Titles
// <------------------>
export const FLOW_TITLE_RENEW = "Renew your policy";
export const FLOW_TITLE_MIDTERM = "Update your policy";
export const POPULAR_MAKES_TITLE = "Most popular makes";
export const UNPOPULAR_MAKES_TITLE = "Other makes";

// <------------------>
//  Shield
// <------------------>
export const SHIELD_MIN_AGREED_VALUE = 1000;
export const SHIELD_MAX_AGREED_VALUE = 150000;

// <------------------>
//  Dates
// <------------------>
export const RENEWAL_GRACE_PERIOD_IN_DAYS = 28;
export const MID_TERM_GRACE_PERIOD_IN_DAYS = 14;

// <------------------>
//  Atoms
// <------------------>
export const ATOM_SESSION_ID = `${SESSION_KEY_PREFIX}AtomSessionId`;

// <------------------>
//  Session
// <------------------>
export const CONTEXT_POLICY_NUMBER_SESSION_KEY = `${SESSION_KEY_PREFIX}ContextPolicyNumber`;
export const CONTEXT_FLOW_STATE_SESSION_KEY = `${SESSION_KEY_PREFIX}FlowState`;
export const WESTPAC_REFERENCE_DATA_SESSION_STATE_KEY = `${SESSION_KEY_PREFIX}WestpacReferenceData`;
export const PAYMENT_ACCOUNTS_SESSION_STATE_KEY = `${SESSION_KEY_PREFIX}PaymentAccounts`;
export const CONFIRMATION_SESSION_STATE_KEY = `${SESSION_KEY_PREFIX}Confirmation`;
export const MANUFACTURERS_SESSION_STATE_KEY = "Manufacturers";
export const PRE_FORM_SESSION_STATE_KEY = "PreForm";

// <------------------>
//  API Client
// <------------------>
export const BFF_API_BASE_URL = process.env.REACT_APP_API_BASE_URL ?? "";
export const LOOKUP_SERVICE_API_BASE_URL = process.env.REACT_APP_LOOKUP_API_BASE_URL ?? "";
export const MFA_OTP_SERVICE_API_BASE_URL = process.env.REACT_APP_MFA_OTP_SERVICE_API_BASE_URL ?? "";

// <------------------>
//  URLS
// <------------------>
export const B2C_PCM_BASE_URL = process.env.REACT_APP_B2C_PCM_BASE_URL ?? "";
export const WESTPAC_SCRIPT = process.env.REACT_APP_WESTPAC_SCRIPT ?? "";

// <------------------>
//  ADB2C
// <------------------>
export const ADB2C_DOMAIN = process.env.REACT_APP_ADB2C_DOMAIN ?? "";
export const ADB2C_TENANT = process.env.REACT_APP_ADB2C_TENANT ?? "";
export const ADB2C_CLIENT_ID = process.env.REACT_APP_ADB2C_CLIENT_ID ?? "";

// <------------------>
//  Analytics
// <------------------>
export const trackPageChangesProps: TrackPageChangesProps = {
  customAnalyticsUrls: [],
  untrackedPages: [
    PRE_FORM_PAGE_URL,
    UTILITY_CALL_US_PAGE_URL,
    UTILITY_ROADBLOCK_PAGE_URL,
    UTILITY_SESSION_TIMEOUT_PAGE_URL,
    UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
  ],
};
