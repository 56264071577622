import { CaravanEndorsementApiException } from "raci-caravanendorsement-clientproxy";
import { HTTP_STATUS_CODE_UNPROCESSABLE_ENTITY } from "raci-react-library";
import { NpeEnvironment } from "raci-react-library-npe";

// <------------------>
//  Test Data
// <------------------>
export const TEST_POLICY_NUMBER = "MGV123456789";

// <------------------>
//  API Client
// <------------------>
export const ENVIRONMENT = (process.env.REACT_APP_ENVIRONMENT ?? "") as NpeEnvironment;
export const NPE_OAUTH_CLIENT_ID = process.env.REACT_APP_NPE_OAUTH_CLIENT_ID ?? "";
export const NPE_OAUTH_AUTHORITY = process.env.REACT_APP_NPE_OAUTH_AUTHORITY ?? "";

// <------------------>
//  Retries
// <------------------>
export const RETRY_AFTER_HEADER = { "retry-after": "1" };

export const RETRYABLE_RESPONSE_WITH_RETRY_HEADER = {
  status: HTTP_STATUS_CODE_UNPROCESSABLE_ENTITY,
  headers: RETRY_AFTER_HEADER,
};
export const RETRYABLE_RESPONSE_WITHOUT_RETRY_HEADER = {
  status: HTTP_STATUS_CODE_UNPROCESSABLE_ENTITY,
};

export const RETRYABLE_API_EXCEPTION_WITH_RETRY_HEADER = new CaravanEndorsementApiException(
  "Retryable Exception with retry header",
  HTTP_STATUS_CODE_UNPROCESSABLE_ENTITY,
  "Retryable Exception with retry header",
  RETRY_AFTER_HEADER,
  undefined,
);
export const RETRYABLE_API_EXCEPTION_WITHOUT_RETRY_HEADER = new CaravanEndorsementApiException(
  "Retryable Exception without retry header",
  HTTP_STATUS_CODE_UNPROCESSABLE_ENTITY,
  "Retryable Exception without retry header",
  {},
  undefined,
);
