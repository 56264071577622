import useFlowState from "../useFlowState";
import { Navigation } from "./types";

export const useNavigationResolver = () => {
  const [flowState] = useFlowState();

  const resolve = (route: Navigation) => {
    return flowState.isMidterm ? route.midterm : route.renewal;
  };

  return resolve;
};

export default useNavigationResolver;
